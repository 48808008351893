import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";

const ReviewCards = ({ gridLayout }) => {
   const StyledReviewCards = styled.div`
      q {
         &:before,
         &:after {
            ${tw`hidden`}
         }
      }
      .masonry-item {
         ${tw`md:mx-3 lg:mx-5`}
         @media (min-width: 768px) {
            width: calc(50% - 1.5rem);
         }
         @media (min-width: 1024px) {
            width: calc(50% - 2.5rem);
         }
      }
   `;
   const data = useStaticQuery(graphql`
      {
         yelp: file(relativePath: { eq: "reviews/yelp.png" }) {
            childImageSharp {
               gatsbyImageData(layout: FIXED, width: 70, placeholder: BLURRED, quality: 100)
            }
         }
         google: file(relativePath: { eq: "reviews/google.png" }) {
            childImageSharp {
               gatsbyImageData(layout: FIXED, width: 76, placeholder: BLURRED, quality: 100)
            }
         }
         facebook: file(relativePath: { eq: "reviews/facebook.png" }) {
            childImageSharp {
               gatsbyImageData(layout: FIXED, width: 95, placeholder: BLURRED, quality: 100)
            }
         }
         houzz: file(relativePath: { eq: "reviews/houzz.png" }) {
            childImageSharp {
               gatsbyImageData(layout: FIXED, width: 98, placeholder: BLURRED, quality: 100)
            }
         }
         thumbtack: file(relativePath: { eq: "reviews/thumbtack.png" }) {
            childImageSharp {
               gatsbyImageData(layout: FIXED, width: 124, placeholder: BLURRED, quality: 100)
            }
         }
         headshot: file(relativePath: { eq: "reviews/User.svg" }) {
            publicURL
         }
      }
   `);

   let gridItemClass = null;

   if (gridLayout === "masonry") {
      gridItemClass = "masonry-item";
   } else {
      gridItemClass = "grid-item";
   }

   const reviews = [
      {
         name: "Mary Kate Qualls",
         review:
            "Conscious Legal and Alanna Pearl are absolutely phenomenal. I can't speak highly enough of them. When I was in the process of launching a business, Conscious Legal guided me to establish my legal, insurance, financial, and tax systems in a watertight way. The parts of becoming a business owner that I expected to be the most stressful and time consuming are now set up to run through clear, straightforward, and sometimes even automated systems. This frees me up to focus on growth, sales and revenue generation. Thank you, Conscious Legal!",
         platform: data.google.childImageSharp.gatsbyImageData,
      },
      {
         name: "Tobey Geise",
         review:
            "Working with Alanna has been amazing, and not what I expected working with a lawyer. She is very knowledgeable, skilled, thorough, and fun! The fun was the unexpected part. She is down-to-earth, relatable, and maintains great rapport with her clients. She is takes the mystery out of the legal jargon and makes everything easy to understand. She gives me options and helps me to see all sides of a situation so I can make the best choice for me and my business. Her care is evident and she goes above and beyond to  make sure every detail is handled. I highly recommend Alanna and Conscious Legal!",
         platform: data.yelp.childImageSharp.gatsbyImageData,
      },
      {
         name: "Rich	Morse",
         review:
            "I am a local personal injury attorney and refer my clients to Alanna for all their estate planning needs. She is the best estate planning attorney in San Diego and my clients love how easy she makes the process. Highly  recommend Conscious Law and Alanna Pearl!",
         platform: data.google.childImageSharp.gatsbyImageData,
      },
      {
         name: "Mike Koser",
         review:
            "Alanna and her team were so easy to work with. Assembling our Living Trust initially felt overwhelming, but Alanna so clearly laid out every step, that the  process was remarkably seamless from start to finish.  She was very accessible,  thoroughly answered all of our questions along the way and now that it's complete, we have peace of mind knowing that our estate is in order.  You can tell that she actually cares deeply about the people she serves. We will be a client for life.",
         platform: data.google.childImageSharp.gatsbyImageData,
      },
      {
         name: "Jay Mccormack",
         review: "Great people and follow through to get it done!.....RIGHT! Thank you",
      },
      {
         name: "Bradon Young",
         review:
            "I have relied on Alanna's legal services for years, I could not imagine a better lawyer to be honest. Over the years I have worked with a handful of lawyers, but none come close to being as well-rounded as Alanna. Not only is she incredibly knowledgeable and professional, but the true care she brings to every conversation or situation is something you can't put a price on. Rather than treating you like a client, she treats you like a friend and gives top quality advice and guidance every step of the way. I can't recommend Conscious Legal enough!",
         headshot: "https://s3-media0.fl.yelpcdn.com/photo/uMK3kvEbJWnjefFIxudeYg/60s.jpg",
         platform: data.facebook.childImageSharp.gatsbyImageData,
      },
      {
         name: "Michael	S.",
         review:
            "My wife and I had put off setting up a formal estate plan. When our financial advisor, who we respect and trust, recommended meeting with Alanna, we decided to see what she had to offer. She explained what she would create for us, what it would cost and how it would be billed, how long it would take, and what information we would have to provide.  She came across as very caring and very smart.  We immediately signed on with her.  Through the process of making those end of life personal decisions, she was very supportive.  She did extra research to determine what choices were available to me as an early Alzheimer's patient, but she was not afraid to question a choice we had made if it did not sound right to her.  She was on our side, looking out for us. Highly Recommended.",
         platform: data.yelp.childImageSharp.gatsbyImageData,
      },
      {
         name: "Desiree	Z.",
         review:
            "When I asked a friend for a recommendation for a lawyer who could make a simple amendment to my trust, I hardly expected to bond to both Alanna and her assistant Liz as much as I did. I honestly feel as though I've made lifelong friends. Liz, you work around the clock to make sure your clients are supported and have everything they need to make decisions for their future, thank you. Alanna, you have literally thought of everything! I have agonized over how to prepare for my family's future when it comes to my kid's needs but you have simplified it all for me, giving me the peace I need to care for my family efficiently. Alanna, thank you for adding us to your family and investing in our future! We are so thankful to have a lawyer who feels like a friend!",
         platform: data.yelp.childImageSharp.gatsbyImageData,
      },
      {
         name: "Tristen	C.",
         review:
            "I was looking for an experienced estate planning attorney who was able to throughly explain all of the many options to me about how to plan for my future, and that of my clients. Alanna is not only through, but thoughtful and approaches with a holistic explanation and all of the options to fully plan for the future.  I recommend her highly!",
         headshot: "https://s3-media0.fl.yelpcdn.com/photo/7c2vpzMOFLLOTTHjQG2oDQ/60s.jpg",
         platform: data.yelp.childImageSharp.gatsbyImageData,
      },
      {
         name: "William	S.",
         review:
            "Alanna and her team are a great resource to set up and personalize your will and trust. They are both knowledgable and thoughtful. They did a personalized video of us to add to our wills for our young children. It is an awesome touch to the service they provide. I would and have recommend them to everyone.",
         platform: data.yelp.childImageSharp.gatsbyImageData,
      },
      {
         name: "Jenny L.",
         review:
            "My family used Conscious Legal to set up a trust and will. Their process was extremely comprehensive and family centered. Not only were all our questions answered, the step by step  process they have created really makes things user friendly.  No one ever wants to talk about what happens if the worst happens, and Alana and Liz made it so comfortable.  I highly recommend their services.",
         headshot: "https://s3-media0.fl.yelpcdn.com/photo/MaSIbE9udBWR7GInN5cEEQ/60s.jpg",
         platform: data.yelp.childImageSharp.gatsbyImageData,
      },
      {
         name: "Ben Gunderson",
         review: "Alanna and Liz are super upbeat, professional and easy to work with.",
         platform: data.facebook.childImageSharp.gatsbyImageData,
      },
      {
         name: "Jayme Tingirides",
         review:
            "Alanna is an absolute genius when it comes to business law. As a business owner, I feel so overwhelmed with all of the hats I already have to wear and the legal side is the last thing I want to dip in to. When I first met with Alanna, she let me know what I needed to know and took care of the rest like a true professional. She really took the time to empower me as a business owner. I am so grateful to have her by my side and definitely would not have made the strides I have with my business without her. Thank you, Alanna!!",
         platform: "",
      },
      {
         name: "Myron Stam",
         review:
            "Alanna has worked with me for about a year and in this short time has established herself as a very reliable attorney for my mid-size bus tour operation. She has consistently provided the company’s executive team with peace of mind when legal issues arise by providing well-researched advice on matters of HR, ADA concerns, tenant/landlord relations, and she brought the company’s Employee Handbook up to date. She responds promptly to my communications, and operates from a position of empathy and integrity.",
         platform: "",
      },
      {
         name: "D.S.",
         review:
            "Since the first contact with Alanna Pearl I've been very favorably impressed with their professionalism & exceptionally conscientious attention to counseling us & preparing our first Living Trust.",
         platform: "",
      },
   ];

   return (
      <StyledReviewCards>
         {reviews.map((review, i) => {
            return (
               <div
                  className={`w-full bg-white border border-solid border-primary-600 rounded-lg mb-8 md:mb-10 p-6 md:pt-8 md:px-7 ${gridItemClass}`}
                  key={i}
               >
                  <div className="flex justify-between items-center mb-6">
                     <div className="flex items-center">
                        <img className="rounded-full" src={review.headshot || data.headshot.publicURL} alt="User Headshot" width="40" />
                        <div className="ml-4">
                           <span className="text-black font-semibold">{review.name}</span>
                        </div>
                     </div>
                     <div className="hidden md:block">
                        <GatsbyImage image={review.platform} />
                     </div>
                  </div>
                  <blockquote>
                     <q className="block mb-6 md:mb-0">{review.review}</q>
                  </blockquote>
                  <div className="block md:hidden">
                     <GatsbyImage image={review.platform} />
                  </div>
               </div>
            );
         })}
      </StyledReviewCards>
   );
};

export default ReviewCards;
