import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import HeroWithOverlay from "../components/Hero/HeroWithOverlay";
import ReviewsGrid from "../components/Reviews/ReviewsGrid";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
   const heroImages = [
      getImage(data.heroDesktop.childImageSharp.gatsbyImageData),
      {
         ...getImage(data.heroMobile.childImageSharp.gatsbyImageData),
         media: `(max-width: 767px)`,
      },
   ];
   return (
      <Layout>
         <SearchEngineOptimization
            title="Reviews | Conscious Legal | Estate Planning Law Firm"
            description="Learn why Conscious Legal is a top estate planning law firm in San Diego. Read reviews from satisfied customers. Schedule a consultation!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <HeroWithOverlay
            image={heroImages}
            heading="Growing with Our Clients"
            subtext="We are honored to have a tremendous amount of positive reviews from our satisfied clients"
            nextBackgroundColor="bg-primary-50"
         >
            <ButtonSolid href="/review-us/" text="Leave a Review" />
         </HeroWithOverlay>

         <section className="pb-24 md:pb-36 bg-primary-50">
            <div className="container">
               <ReviewsGrid gridLayout="masonry" />
            </div>
         </section>

         <CallToAction headingLevel="h2" />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Reviews_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Reviews_TW.jpg" }) {
         publicURL
      }
      heroDesktop: file(relativePath: { eq: "reviews/1.0-Hero-desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      heroMobile: file(relativePath: { eq: "reviews/1.0-Hero-mobile.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
   }
`;
export default Page;
